import React from "react";
import "@fontsource/space-grotesk";
import "./global.css";
import type { GatsbyBrowser } from "gatsby";

const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => {
  return <div className="wrap">{element}</div>;
};

export { wrapPageElement };
